import { render, staticRenderFns } from "./BookingServiceRequestConsultationForm.vue?vue&type=template&id=8d48bbea&scoped=true&"
import script from "./BookingServiceRequestConsultationForm.vue?vue&type=script&lang=js&"
export * from "./BookingServiceRequestConsultationForm.vue?vue&type=script&lang=js&"
import style0 from "./BookingServiceRequestConsultationForm.vue?vue&type=style&index=0&id=8d48bbea&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8d48bbea",
  null
  
)

export default component.exports