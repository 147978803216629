<template>
  <b-overlay
    :show="stylistStore.isPending"
    variant="secondary"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="lg"
    class="min-vh-100"
  >
    <div
      id="user-profile"
    >
      <stylist-page-header :header-data="header" />
      <!-- Booking -->
      <section
        id="booking-service"
        class="pb-2 py-md-4 px-sm-2 px-lg-4"
      >
        <b-container>
          <b-row>
            <b-col
              lg="3"
              order-lg="2"
              class="second-column"
            />
            <b-col
              lg="9"
              class="booking-fields-box"
            >
              <service-request-consultation-form />
            </b-col>
          </b-row>
        </b-container>
      </section>
      <!--/ Booking  -->
    </div>
  </b-overlay>
</template>

<script>
import {
  BRow, BCol, BOverlay, BContainer,
} from 'bootstrap-vue'
import StylistPageHeader from '@/components/stylist/stylist-page/StylistPageHeader.vue'
import { mapActions, mapState } from 'vuex'
import { AlertTriangleIcon } from 'vue-feather-icons'
import { TYPE } from 'vue-toastification'
import ServiceRequestConsultationForm from '@/components/booking/booking-service/BookingServiceRequestConsultationForm.vue'

/* eslint-disable global-require */
export default {
  components: {
    ServiceRequestConsultationForm,
    BRow,
    BCol,
    BContainer,
    BOverlay,
    StylistPageHeader,
  },
  data() {
    return {
      header: {
        avatar: require('@/assets/images/user-uploads/stylist-profile/avatars/avatar-s-2.jpg'),
        businessName: null,
        coverImg: require('@/assets/images/user-uploads/stylist-profile/backgrounds/timeline.jpg'),
      },
    }
  },
  computed: {
    ...mapState('StylistPageStoreModule', {
      stylistStore: state => state.stylist,
    }),
    ...mapState('BookingServiceStoreModule', {
      customerLoggedStore: state => state.customerLogged,
    }),
  },
  watch: {
    stylistStore: {
      deep: true,
      handler(newStylistData) {
        if (newStylistData.response) {
          this.header.businessName = newStylistData.response.businessName
        }
        if (newStylistData.error) {
          this.$toast('Error fetching stylist data!', {
            icon: AlertTriangleIcon,
            type: TYPE.ERROR,
          })
        }
      },
    },
    customerLoggedStore: {
      deep: true,
      handler(newCustomerLogged) {
        if (newCustomerLogged.error) {
          this.$toast('Error fetching customer data!', {
            icon: AlertTriangleIcon,
            type: TYPE.ERROR,
          })
        }
      },
    },
  },
  created() {
    this.fetchStylistPublicServices()
  },
  mounted() {
    this.getHeaderData()
  },
  methods: {
    ...mapActions('BookingServiceStoreModule', [
      'fetchCustomerData',
      'fetchStylistPublicServices',
    ]),
    ...mapActions('StylistPageStoreModule', [
      'fetchPublicStylist',
    ]),
    getHeaderData() {
      if (this.stylistStore.response.businessName === null) {
        this.fetchPublicStylist(this.$store.getters['app/getSubdomain'])
      }
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-profile.scss';
@media (max-width: 991.98px) {
  .booking-fields-box {
    margin-top: 2rem;
  }
}
</style>
<style lang="scss" scoped>
@media (max-width: 991.98px) {
  #user-profile{
    padding-bottom: 6rem;
  }
}
</style>
