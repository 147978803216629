var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tbb-card',{attrs:{"title":"Booking for"}},[_c('validation-observer',{ref:"refFormCustomerObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
return _c('b-form',{attrs:{"id":"reserve-appointment-form"},on:{"submit":function($event){$event.preventDefault();_vm.sendRequest(validate())}}},[_c('b-form-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"cols":"12","lg":"4","xl":""}},[_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Name","label-for":"name"}},[_c('b-form-input',{attrs:{"id":"name","state":errors[0] ? false : null,"size":"lg"},model:{value:(_vm.customer.name),callback:function ($$v) {_vm.$set(_vm.customer, "name", $$v)},expression:"customer.name"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","lg":"4","xl":""}},[_c('b-form-group',{attrs:{"label":"Last name","label-for":"last-name"}},[_c('b-form-input',{attrs:{"id":"last-name","size":"lg"},model:{value:(_vm.customer.lastName),callback:function ($$v) {_vm.$set(_vm.customer, "lastName", $$v)},expression:"customer.lastName"}})],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"4","xl":""}},[_c('validation-provider',{attrs:{"name":"Cell phone number","rules":"required|regex:^(\\+?[0-9]+)$|checkPhoneNumberFormat"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Cell phone number","label-for":"phone"}},[_c('b-form-input',{attrs:{"id":"phone","name":"phone","state":errors[0] ? false : null,"size":"lg"},model:{value:(_vm.customer.phone),callback:function ($$v) {_vm.$set(_vm.customer, "phone", $$v)},expression:"customer.phone"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","lg":"4","xl":""}},[_c('validation-provider',{attrs:{"name":"Email","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('b-form-input',{attrs:{"id":"email","name":"email","state":errors[0] ? false : null,"size":"lg"},model:{value:(_vm.customer.email),callback:function ($$v) {_vm.$set(_vm.customer, "email", $$v)},expression:"customer.email"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1)],1),_c('b-form-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Your inquiry"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Your inquiry","label-for":"inquiry"}},[_c('b-form-textarea',{attrs:{"id":"inquiry","name":"inquiry","state":errors[0] ? false : null,"size":"lg","rows":"4"},model:{value:(_vm.note),callback:function ($$v) {_vm.note=$$v},expression:"note"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":function($event){return _vm.backToFirstStep()}}},[_vm._v(" Back ")]),_c('b-button',{staticClass:"ml-1",attrs:{"variant":"primary","type":"submit"}},[_vm._v(" Send ")])],1)],1)],1)}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }