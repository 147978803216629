<template>
  <tbb-card title="Booking for">
    <validation-observer
      ref="refFormCustomerObserver"
    >
      <b-form
        id="reserve-appointment-form"
        slot-scope="{ validate }"
        @submit.prevent="sendRequest(validate())"
      >
        <b-form-row
          class="mt-2"
        >
          <b-col
            cols="12"
            lg="4"
            xl
          >
            <!-- Field: Name -->
            <validation-provider
              name="Name"
              rules="required"
            >
              <b-form-group
                slot-scope="{ valid, errors }"
                label="Name"
                label-for="name"
              >
                <b-form-input
                  id="name"
                  v-model="customer.name"
                  :state="errors[0] ? false : null"
                  size="lg"
                />
                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            cols="12"
            lg="4"
            xl
          >
            <b-form-group
              label="Last name"
              label-for="last-name"
            >
              <b-form-input
                id="last-name"
                v-model="customer.lastName"
                size="lg"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            lg="4"
            xl
          >
            <!-- Cell phone number -->
            <validation-provider
              name="Cell phone number"
              rules="required|regex:^(\+?[0-9]+)$|checkPhoneNumberFormat"
            >
              <b-form-group
                slot-scope="{ valid, errors }"
                label="Cell phone number"
                label-for="phone"
              >
                <b-form-input
                  id="phone"
                  v-model="customer.phone"
                  name="phone"
                  :state="errors[0] ? false : null"
                  size="lg"
                />
                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            cols="12"
            lg="4"
            xl
          >
            <!-- Cell email -->
            <validation-provider
              name="Email"
              rules="email"
            >
              <b-form-group
                slot-scope="{ valid, errors }"
                label="Email"
                label-for="email"
              >
                <b-form-input
                  id="email"
                  v-model="customer.email"
                  name="email"
                  :state="errors[0] ? false : null"
                  size="lg"
                />
                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-form-row>
        <b-form-row
          class="mt-2"
        >
          <b-col cols="12">
            <!-- About your appointment -->
            <validation-provider
              name="Your inquiry"
            >
              <b-form-group
                slot-scope="{ valid, errors }"
                label="Your inquiry"
                label-for="inquiry"
              >
                <b-form-textarea
                  id="inquiry"
                  v-model="note"
                  name="inquiry"
                  :state="errors[0] ? false : null"
                  size="lg"
                  rows="4"
                />
                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            cols="12"
            class="text-right"
          >
            <b-button
              variant="outline-secondary"
              @click="backToFirstStep()"
            >
              Back
            </b-button>
            <b-button
              variant="primary"
              type="submit"
              class="ml-1"
            >
              Send
            </b-button>
          </b-col>
        </b-form-row>
      </b-form>
    </validation-observer>
  </tbb-card>
</template>

<script>
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import {
  BFormInvalidFeedback, BFormRow, BFormGroup, BFormInput, BFormTextarea, BButton, BCol, BForm,
} from 'bootstrap-vue'
import {
  mapActions, mapGetters, mapState,
} from 'vuex'
import { BOOKING_FIRST_STEP_PAGE, STYLIST_PAGE } from '@/router/routes/routes-names'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import TbbCard from '../../sites/TbbCard.vue'

extend('checkPhoneNumberFormat', {
  validate(value) {
    // eslint-disable-next-line no-mixed-operators
    return value == null || value != null && value.length < 18
  },
  computesRequired: true,
  message: 'The Cell phone number field format is invalid',
})

export default {
  name: 'ServiceRequestConsultationForm',
  components: {
    BFormInvalidFeedback,
    BFormRow,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BCol,
    BForm,
    ValidationObserver,
    ValidationProvider,
    TbbCard,
  },
  data() {
    return {
      customer: {
        name: null,
        lastName: null,
        phone: null,
        email: null,
      },
      note: null,
      elements: null,
      service: null,
    }
  },
  computed: {
    ...mapState('BookingServiceStoreModule', {
      customerLoggedStore: state => state.customerLogged,
      publicServicesStore: state => state.publicServices,
    }),
    ...mapGetters('BookingServiceStoreModule', [
      'serviceDataGetter',
    ]),
  },
  watch: {
    customerLoggedStore: {
      deep: true,
      handler(newCustomerLoggedData) {
        if (newCustomerLoggedData.response) {
          this.customer = { ...this.customer, ...newCustomerLoggedData.response }
          if (this.publicServicesStore.response !== null) {
            this.updateNoteInquiry()
          }
        }
      },
    },
    publicServicesStore: {
      deep: true,
      handler(newPublicServices) {
        if (newPublicServices.response) {
          this.updateNoteInquiry()
        }
      },
    },
  },
  mounted() {
  },
  async created() {
    await this.$auth.init()
    this.$auth.auth0Client.getUser().then(user => {
      if (typeof user !== 'undefined') {
        this.customer.name = user?.given_name
        this.customer.lastName = user?.family_name
        this.customer.email = user?.email
      }
    })
  },
  methods: {
    ...mapActions('BookingServiceStoreModule', [
      'sendServiceRequestConsultation',
    ]),
    async sendRequest(validate) {
      await validate.then(async success => {
        if (success) {
          try {
            await this.sendServiceRequestConsultation(this.prepareDataToSave())
            this.success()
          } catch (e) {
            this.error()
          }
        }
      })
    },
    success() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Your inquiry has been sent',
          icon: 'AlertTriangleIcon',
          variant: 'success',
        },
      })
      this.$router.push({ name: STYLIST_PAGE })
    },
    error() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Something went wrong. Please try again.',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    },
    updateNoteInquiry() {
      this.service = this.serviceDataGetter(this.$route.params.id)
      this.note = `requesting a consultation for ${this.service.category.name}/${this.service.name}`
    },
    prepareDataToSave() {
      return {
        customer: { ...this.customer },
        service: { ...this.service },
        note: this.note,
      }
    },
    backToFirstStep() {
      this.$router.push({ name: BOOKING_FIRST_STEP_PAGE })
    },
  },
}
</script>
<style lang="scss" scoped>
.datepicker-booking-service {
  width: 20rem !important;
}
</style>
